import MicroModal from "micromodal"; // es6 module
import AOS from "aos";

const BASE_URL = "https://justchapp.com";

(function() {
  "use strict";

  window.addEventListener(
    "load",
    function() {
      MicroModal.init();
      formEmail();
      contactFormSend();
      AOS.init();
      formError();

      // scroll into view
      document
        .querySelector(".link-aplication")
        .addEventListener("click", function() {
          document
            .querySelector(".aplication")
            .scrollIntoView({ behavior: "smooth" });
        });

      document
        .querySelector(".link-security")
        .addEventListener("click", function() {
          document
            .querySelector(".security")
            .scrollIntoView({ behavior: "smooth" });
        });

      document
        .querySelector(".link-profiles")
        .addEventListener("click", function() {
          document
            .querySelector(".profiles")
            .scrollIntoView({ behavior: "smooth" });
        });

      // modal Email
      function formEmail() {
        const emailForm = document.getElementsByClassName("form-email");

        for (const form of emailForm) {
          form.addEventListener("submit", function(event) {
            event.preventDefault();

            let submitBtn = form.querySelector(".submit");
            submitBtn.disabled = true;

            let formData = new FormData(form);

            fetch(`${BASE_URL}/subscribers`, {
              method: "POST",
              body: formData
            })
              .then(response => response.json())
              .then(({ count }) => {
                subscribersCounter(count);
              })
              .then(
                form.reset(),
                (submitBtn.disabled = false),
                MicroModal.show("modal-email")
              );
          });
        }
      }

      function subscribersCounter(count) {
        document.querySelector("#subscribers-counter").innerHTML = count;
        if (count < 1000) {
          document.querySelector(".email__counter").style.display = "none";
        } else {
          document.querySelector(".email__counter").style.display = "block";
        }
      }

      // Contact form send
      function contactFormSend() {
        const formContact = document.getElementById("contact__form");

        formContact.addEventListener("submit", function(event) {
          event.preventDefault();

          let submitBtn = formContact.querySelector(".submit");
          submitBtn.disabled = true;

          let formData = new FormData(formContact);

          fetch(`${BASE_URL}/feedbacks`, {
            method: "POST",
            body: formData
          }).then(
            MicroModal.close("modal-contact"),
            formContact.reset(),
            (submitBtn.disabled = false),
            MicroModal.show("modal-success")
          );
        });
      }

      function formError() {
        const forms = document.querySelectorAll("form");
        for (const form of forms) {
          form.querySelector("button").addEventListener("click", function() {
            form.classList.add("submitted");
          });

          form.addEventListener(
            "invalid",
            function(event) {
              event.preventDefault();
            },
            true
          );

          form.addEventListener("submit", function() {
            form.classList.remove("submitted");
          });
        }
      }
    },
    false
  );
})();
